<template>
  <div class="Serf-Consent" data-e2e="consent">
    <v-tabs v-model="selectedTab" @change="clearSignature" grow centered icons-and-text show-arrows>
      <v-tab v-if="solicitation" href="#tab-signature" data-e2e="button-tab-signature">
        <span class="text-capitalize" :class="{ caption: isXs }"> {{ $t('electronic-signature') }} </span>
        <v-icon>mdi-fountain-pen</v-icon>
      </v-tab>

      <v-tab v-if="!noScan" href="#tab-scan">
        <span class="text-capitalize" :class="{ caption: isXs }"> {{ $t('scan-document') }} </span>
        <v-icon>mdi-camera-outline</v-icon>
      </v-tab>
      <slot name="tabs"></slot>
    </v-tabs>

    <v-card class="pa-8">
      <slot name="description" />

      <div v-if="solicitation && selectedTab === 'tab-signature'">
        <p class="primary--text">
          Veuillez signer directement dans l'encadré ci-dessous en utilisant votre souris d'ordinateur.
        </p>
        <div class="elevation-1">
          <div class="wrapper-signature turpud">
            <VueSignaturePad :options="{ onEnd: checkSignature }" ref="signaturePad" class="signature-pad" />
          </div>
        </div>
        <template slot="error"></template>
        <div class="mt-10 text-center">
          <slot name="deleteSignatureButton">
            <v-btn @click.stop="clearSignature" :disabled="!isSigned" class="mx-4 px-6 py-2" color="primary" depressed>
              {{ $t('serfForm.consent.clear') }}
            </v-btn>
          </slot>
          <slot name="saveSignatureButton">
            <v-btn
              @click.stop="saveSignature"
              :disabled="!isSigned"
              class="mx-4 px-6 py-2"
              color="primary"
              depressed
              v-cy="$cy.congrex.solicitation.submit"
            >
              <v-icon left>mdi-content-save-outline</v-icon>{{ $t('serfForm.consent.save') }}
            </v-btn>
          </slot>
        </div>
      </div>

      <div v-else>
        <div v-if="value">
          <div class="signature-container signature-container--image text-center d-flex align-center justify-center">
            <v-card class="scan-container">
              <v-row no-gutters class="px-3 py-1" align="center">
                <span class="scan-title ellipsis">
                  {{ $refs.myInput.files[0] ? $refs.myInput.files[0].name : '' }}
                </span>
                <v-spacer />
                <v-btn icon @click="removeUploadedImage"> <v-icon>mdi-close</v-icon> </v-btn>
              </v-row>
              <PDF v-if="value && value.startsWith('data:application/pdf')" :src="value" />
              <img v-else-if="value" :src="value" height="83%" class="px-2" />
            </v-card>
          </div>
        </div>

        <v-row v-else-if="!solicitation" justify="center">
          <v-col cols="auto">
            <v-btn color="primary" depressed @click="$refs.myInput.click()">{{ $t('serfForm.uploadScan') }}</v-btn>
          </v-col>
        </v-row>

        <template slot="error"></template>
        <div class="mt-10 text-center">
          <slot name="saveUploadButton">
            <v-btn @click.stop="save" :disabled="!isUpload" class="px-6 py-2" color="primary" depressed>
              <v-icon left>mdi-content-save-outline</v-icon>{{ $t('serfForm.consent.save') }}
            </v-btn>
          </slot>
        </div>
      </div>
      <slot name="footer" />
    </v-card>

    <input
      ref="myInput"
      type="file"
      accept="image/png, image/jpeg, application/pdf"
      class="d-none"
      @change="updateImageDisplay"
    />
  </div>
</template>

<script>
import PDF from 'vue-pdf'

import VueSignaturePad from 'vue-signature-pad'

export default {
  name: 'Serf_Consent',

  components: {
    PDF,
    VueSignaturePad
  },

  props: {
    value: String,
    noScan: {
      type: Boolean,
      default: false
    },
    solicitation: Boolean
  },

  data() {
    return {
      selectedTab: this.solicitation ? 'tab-signature' : 'tab-scan',
      isSigned: false,
      isUpload: false,
      reader: new FileReader(),
      isXs: this.$vuetify.breakpoint.name === 'xs'
    }
  },

  mounted() {
    this.reader.addEventListener(
      'load',
      () => {
        this.$emit('signature', null)
        this.$emit('scan', this.reader.result)
        this.isSigned = false
        this.isUpload = true
      },
      false
    )
  },

  methods: {
    clearSignature() {
      this.$emit('signature', null)
      this.$emit('scan', null)
      this.isSigned = false
      this.isUpload = false
      if (this.$refs.signaturePad) {
        this.$refs.signaturePad.clearSignature()
      }
    },
    checkSignature() {
      this.isSigned = this.$refs.signaturePad && !this.$refs.signaturePad.isEmpty()
    },
    saveSignature() {
      // Clear upload file by security
      this.$emit('scan', null)
      this.isUpload = false

      // Get Signature
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()

      // If empty, clear signature
      if (isEmpty) {
        this.$emit('signature', null)
        this.isSigned = false
        return
      }

      // Else, send signature
      this.$emit('signature', data)
      this.isSigned = true

      // Save
      this.save()
    },
    save() {
      if (this.isSigned || this.isUpload) {
        this.$emit('save')
      }
    },
    removeUploadedImage() {
      this.$emit('scan', null)
      this.isUpload = false
      this.selectedTab = 'tab-scan'
      this.$refs.myInput.value = null
    },
    updateImageDisplay() {
      this.isSigned = false
      const file = this.$refs.myInput.files[0]
      if (file) {
        this.reader.readAsDataURL(file)
      } else {
        this.selectedTab = 'tab-signature'
      }
    }
  },

  watch: {
    /**
     * Only enabled for solicitation
     * @param {'tab-signature' | 'tab-scan'} value
     */
    selectedTab(value) {
      if (!this.solicitation) return

      if (value !== 'tab-scan') return

      if (!this.isSigned) {
        this.$refs.myInput.click()
      }
    }
  },

  beforeDestroy() {
    this.reader.removeEventListener(
      'load',
      () => {
        this.$emit('signature', null)
        this.$emit('scan', null)
        this.isSigned = false
        this.isUpload = false
      },
      false
    )
  }
}
</script>

<style scoped lang="scss">
.Serf-Consent {
  .signature--ratio {
    width: 100%;
    height: 100%;
    padding-top: 50%;
    position: relative;
  }

  .signature-container {
    border: 1px solid lightgrey;
    cursor: pointer;
    &.signature-container--signature {
      width: 100%;
      height: 100%;
      padding-top: 50%;
      position: relative;
      img {
        position: absolute;
        top: 0;
      }
    }
    &.signature-container--image {
      height: 39.5vh;
    }
  }

  .scan-container {
    height: 80%;
    max-width: 90%;
    overflow: hidden;

    .scan-title {
      max-width: 450px;
    }
  }

  .footer-paragraph {
    font-size: 11px;
  }
}

.Consent-openSignatureModalButton {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.2em;
  color: #dadada;
  font-weight: 700;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 1.2em;
  }
}

.wrapper-signature {
  border: 1px solid #9e9e9e;
  width: 100%;
  height: 100%;
  padding-top: 35vh;
  position: relative;
  .signature-pad {
    position: absolute;
    top: 0;
  }
  &::before {
    content: 'SIGNER ICI';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #efefef;
    font-size: 3em;
  }
}
</style>
